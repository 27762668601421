import React, { Component } from "react"
import Header from "./header"
import Footer from "./footer"
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col, Form } from 'react-bootstrap';
import Slider from "react-slick";
import axios from 'axios'

import img_cyberhealth from "../images/services/cyberhealth-1.png"
import img_virtuchief from "../images/services/virtuchief.png"
import img_penetration from "../images/services/penetration.jpg"
import img_security_audit from "../images/services/security_audit.png"
import img_enterprise from "../images/services/enterprise.png"
import img_policyDev from "../images/services/policyDev.png"

import img_industry_1 from "../images/industry/1.jpg"
import img_industry_2 from "../images/industry/2.jpg"
import img_industry_3 from "../images/industry/3.jpg"
import img_industry_4 from "../images/industry/4.jpg"
import img_industry_5 from "../images/industry/5.jpg"
import img_industry_6 from "../images/industry/6.jpg"
import img_industry_7 from "../images/industry/7.jpg"
import img_industry_8 from "../images/industry/8.jpg"

import img_client_GenesysLogo from "../images/clients/GenesysLogo.jpg"
import img_client_GDIT from "../images/clients/GDIT-Logo.png"
import img_client_FBI from "../images/clients/FBI-Logo.jpg"
import img_client_Avertium from "../images/clients/Avertium-Logo.jpg"
import img_client_ZT from "../images/clients/ZT-Logo.jpg"
import img_client_MergeIT from "../images/clients/MergeIT-Logo.png"


const experience_slider = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 586,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const clients_slider = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 586,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const experience_sliderData = [
  {
    img: img_industry_1,
    title: 'National Institute of Standards and Technology (NIST)'
  },
  {
    img: img_industry_2,
    title: 'Financial Compliance'
  },
  {
    img: img_industry_3,
    title: 'Government Health'
  },
  {
    img: img_industry_4,
    title: 'National Institute of Standards and Technology (NIST)'
  },
  {
    img: img_industry_5,
    title: 'Law Enforcement'
  },
  {
    img: img_industry_6,
    title: 'Health Service Providers'
  },
  {
    img: img_industry_7,
    title: 'Health Service Providers'
  },
  {
    img: img_industry_8,
    title: 'Merchant and Service Provider Credit Card Processing'
  }
]

const clients_sliderData = [
  {
    img: img_client_GenesysLogo,
    title: 'Genesys'
  },
  {
    img: img_client_GDIT,
    title: 'GDIT'
  },
  {
    img: img_client_FBI,
    title: 'FBI'
  },
  {
    img: img_client_Avertium,
    title: 'AVERTIUM'
  },
  {
    img: img_client_ZT,
    title: 'ZT'
  },
  {
    img: img_client_MergeIT,
    title: 'MergeIT'
  }
]

// markup
class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      subject: '',
      message: '',
      fixedForm: ''
    }
  }

  handleSubmit(type) {
    if (type === 'contact' && this.state.company === '') {
      alert('Please enter your company!');
      return false;
    } else if (this.state.email === '') {
      alert('Please enter your email!');
      return false;
    } else if (this.state.firstName === '') {
      alert('Please enter your first name!');
      return false;
    } else if (type === 'contact' && this.state.lastName === '') {
      alert('Please enter your last name!');
      return false;
    } else if (type === 'contact' && this.state.phone === '') {
      alert('Please enter your phone!');
      return false;
    }
    delete this.state.fixedForm;
    axios({
      method: "POST",
      url: "https://getform.io/f/3cdc58b4-588f-4498-804b-63c30fae62e9",
      data: this.state
    }).then((response) => {
      console.log(response);
      alert("Message Sent.");
      this.resetForm();
      this.setState({ fixedForm: '' })
      const isBrowser = () => typeof window !== "undefined"
      const path = isBrowser() && window.location.replace('/');
      /* if (response.data.status === 'success') {
        alert("Message Sent.");
        this.resetForm()
      } else if (response.data.status === 'fail') {
        if (response.data.error[0]) {
          alert(response.data.error[0])
        } else {
          alert("Something went wrong. Please try again later!")
        }
      } */
    })
  }

  resetForm() {
    this.setState({ company: '', firstName: '', lastName: '', email: '', phone: '', subject: '', message: '' })
  }

  render() {
    return (
      <>
        <Header pageTitle="ESG" />
        <section className="py-3 px-md-5 mx-xl-5 services">
          <Container>
            <h2 className="text-center font-weight-bold">OUR <span className="text-theme">SERVICES</span></h2>
            <div className="heading-titel-line my-3"></div>
            <Row>
              <Col md="12" lg="6" className="my-2" id="cyber-health-check">
                <Row className="px-2">
                  <Col md="3">
                    <img src={img_cyberhealth} alt="Cyber Health Check" className="img-fluid" />
                  </Col>
                  <Col md="9">
                    <h5>
                      <a href="/cyber-health-check-chc">Cyber Health Check</a>
                    </h5>
                    <p>CHC is an easy-to-use, cloud-based cybersecurity audit tool for conducting
                    audits and assessments with a single click report generator. It is the perfect auditor’s toolkit.</p>
                    <a href="/cyber-health-check-chc" className="pull-right">Read More</a>
                  </Col>
                </Row>
              </Col>
              <Col md="12" lg="6" className="my-2" id="virtual-chief-info">
                <Row className="px-2">
                  <Col md="3">
                    <img src={img_virtuchief} alt="Virtual Chief Information Security Officer" className="img-fluid" />
                  </Col>
                  <Col md="9">
                    <h5>
                      <a href="/consulting-services">Virtual Chief Information Security Officer</a>
                    </h5>
                    <p>ESG provides experienced C-level executives to develop and/or augment the current cyber security department.</p>
                    <a href="/consulting-services" className="pull-right">Read More</a>
                  </Col>
                </Row>
              </Col>
              <Col md="12" lg="6" className="my-2" id="penetration-testing">
                <Row className="px-2">
                  <Col md="3">
                    <img src={img_penetration} alt="Penetration And Vulnerability Testing" className="img-fluid" />
                  </Col>
                  <Col md="9">
                    <h5>
                      <a href="/penetration-and-vulnerability-testing">Penetration And Vulnerability Testing</a>
                    </h5>
                    <p>It is an industry best practice to perform quarterly internal and external vulnerability assessments
                    to assess the organization’s change and configuration management program as well as the technical security
                      controls in place.</p>
                    <a href="/penetration-and-vulnerability-testing" className="pull-right">Read More</a>
                  </Col>
                </Row>
              </Col>
              <Col md="12" lg="6" className="my-2" id="security-audits">
                <Row className="px-2">
                  <Col md="3">
                    <img src={img_security_audit} alt="Security Audits/Assessments" className="img-fluid" />
                  </Col>
                  <Col md="9">
                    <h5>
                      <a href="/#security-audits">Security Audits/Assessments</a>
                    </h5>
                    <p>One of the first steps to reduce the risk digital threats and provide security assurances is to conduct an audit.
                      Through this process, a detailed investigation is carried out on the vulnerabilities of digital platform(s).</p>
                    <a href="/#security-audits" className="pull-right">Read More</a>
                  </Col>
                </Row>
              </Col>
              <Col md="12" lg="6" className="my-2" id="enterprice-security-review">
                <Row className="px-2">
                  <Col md="3">
                    <img src={img_enterprise} alt="Enterprise Security Baseline Review" className="img-fluid" />
                  </Col>
                  <Col md="9">
                    <h5>
                      <a href="/#enterprice-security-review">Enterprise Security Baseline Review</a>
                    </h5>
                    <p>An often-overlooked tool by many IT executives and managers is to have an independent
                      third-party review of the baseline configuration files of all network devices.</p>
                    <a href="/#enterprice-security-review" className="pull-right">Read More</a>
                  </Col>
                </Row>
              </Col>
              <Col md="12" lg="6" className="my-2" id="policy-development">
                <Row className="px-2">
                  <Col md="3">
                    <img src={img_policyDev} alt="Policy Development" className="img-fluid" />
                  </Col>
                  <Col md="9">
                    <h5>
                      <a href="/policy-development">Policy Development</a>
                    </h5>
                    <p>For organizations that require Governance policy development, ESG develops industry specific policy
                    libraries tailored for each organization to satisfy regulatory compliance as well as educate corporate
                      leadership on their responsibilities.</p>
                    <a href="/policy-development" className="pull-right">Read More</a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-2 px-md-5 px-xl-5 free-quote bg-theme">
          <Container className="p-3">
            <Row>
              <Col md="9">
                <h4>Let's Start A New Enterprise Solutions Group Project Together.</h4>
              </Col>
              <Col md="3">
                <a href="javascript:void(0)" className="btn btn-outline-primary" onClick={() => this.setState({ fixedForm: 'active' })}>FREE QUOTE </a>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-3 px-md-5 mx-xl-5">
          <Container>
            <h2 className="text-center font-weight-bold">INDUSTRY <span className="text-theme">EXPERIENCE</span></h2>
            <div className="heading-titel-line my-3"></div>
            <Slider {...experience_slider} className="industry-experience-slider">
              {experience_sliderData.map((data) => {
                return (
                  <Row>
                    <Col>
                      <div className="border">
                        <img src={data.img} alt={data.title} className="img-fluid" />
                        <div className="text-center bg-theme p-1">
                          <h5 className="text-white">{data.title}</h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
              })}
            </Slider>
          </Container>
        </section>
        <section className="py-3 px-md-5 mx-xl-5 mt-3">
          <Container>
            <h2 className="text-center font-weight-bold">OUR <span className="text-theme">CLIENTS</span></h2>
            <div className="heading-titel-line my-3"></div>
            <Slider {...clients_slider} className="clients-slider">
              {clients_sliderData.map((data) => {
                return (
                  <Row>
                    <Col>
                      <div>
                        <img src={data.img} alt={data.title} className="img-fluid" />
                        <div className="text-center p-1 mt-2">
                          <h5>{data.title}</h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
              })}
            </Slider>
          </Container>
        </section>
        <section className={`fixed-form rounded-lg ${this.state.fixedForm}`}>
          <div className="border border-primary rounded-circle"
            onClick={() => this.setState({ fixedForm: 'active' })}
            style={{ cursor: 'pointer' }}>
            <i className="fa fa-comments-o fa-3x text-theme"></i>
          </div>
          <Form onSubmit={() => this.handleSubmit('quote')} method="POST">
            <Row>
              <Col md="12">
                <i className="fa fa-times fa-lg pull-right text-white" 
                style={{ cursor: 'pointer' }} 
                onClick={() => this.setState({ fixedForm: '' })}></i>
                <h4 className="text-center bg-theme text-white p-1">Free Quote</h4>
                <input
                  type="text"
                  className="form-control my-1"
                  placeholder="Enter Your Name *"
                  onChange={e => this.setState({ firstName: e.target.value })} />
              </Col>
              <Col md="12">
                <input
                  type="email"
                  className="form-control my-1"
                  placeholder="Enter Your Email *"
                  onChange={e => this.setState({ email: e.target.value })} />
              </Col>
              <Col md="12">
                <input
                  type="text"
                  className="form-control my-1"
                  placeholder="Subject"
                  onChange={e => this.setState({ subject: e.target.value })} />
              </Col>
              <Col md="12">
                <input
                  type="text"
                  className="form-control my-1"
                  placeholder="Message"
                  onChange={e => this.setState({ message: e.target.value })} />
              </Col>
              <Col md="12">
                <button
                  type="button" onClick={() => this.handleSubmit('quote')}
                  className="form-control my-1 btn btn-primary"
                >Submit</button>
              </Col>
            </Row>
          </Form>
        </section>
        <Footer />
      </>
    )
  }

}

export default IndexPage
